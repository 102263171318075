import { Typography } from "@hero/krypton"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { API_URI } from "../../../../env_variables"

const CGUDescription = styled(Typography)`
  color: ${({ theme }) => theme.colors.grey.$500};
  flex: 1;
  margin-left: 1.25rem;
  margin-top: -0.25rem;
  font-size: 0.685rem;
`

const Input = styled.input`
  position: absolute;
  left: 0;
  opacity: 0.01;

  & + label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;

    border: 1px solid ${({ theme }) => theme.colors.grey.$500};
    border-radius: 0.125rem;
    width: 0.75rem;
    height: 0.75rem;

    transition: all 0.2s ease-in-out;
    opacity: 1;

    transform: rotate(0deg);
  }
  &:checked + label:before {
    opacity: 0;
    transform: rotate(180deg);
  }

  & + label:after {
    content: "✓";
    position: absolute;
    left: 0;
    top: 0;

    line-height: 1rem;
    width: 1rem;
    height: 1rem;
    font-size: 1.125rem;
    opacity: 0;
    transform: rotate(180deg);
  }
  &:checked + label:after {
    transition: all 0.2s ease-in-out;
    opacity: 1;
    transform: rotate(0deg);
  }

  &:checked:focus + label:after {
    box-shadow: 0 0 4px #3d3153;
    ouline: 1px solid #3d3153;
  }
  &:focus + label:before {
    box-shadow: 0 0 4px #3d3153;
    ouline: 1px solid #3d3153;
  }
`

const Label = styled.label`
  position: relative;
  cursor: pointer;
  margin-left: 0.5rem;
  > p > a {
    margin-left: 0.2rem;
    text-decoration: underline;
  }
`

export const CGUBlock: React.FC<{
  paymentId: string
  toggle: () => void
  className?: string
  id: string
  dataTestId: string
}> = ({ toggle, paymentId, className, id, dataTestId = "cgublock-checkbox" }) => {
  const { t } = useTranslation()

  return (
    <>
      <Input data-test-id={dataTestId} name="cgu" id={id} type="checkbox" onChange={toggle} />
      <Label className={className} htmlFor={id}>
        <CGUDescription $variant="caption-2">
          {t("paymentPage.cgu.acceptance")}
          <a rel="noreferrer" href="../../../../assets/hero_cgu.pdf" download>
            {t("paymentPage.cgu.link")}
          </a>
          .
        </CGUDescription>
      </Label>
    </>
  )
}
